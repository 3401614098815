/* You can add global styles to this file, and also import other style files */
@import '~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';
@import './assets/scss/flexbox';
@import './assets/scss/variables';


.success-snackbar,
.error-snackbar {
  all: initial;
  color: $white;
}

.success-snackbar {
  background-color: $success;
  color: $black;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.error-snackbar {
  background-color: $error;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1200;
  background-color: rgba(lightgrey, .5);
  width: 100%;
  height: 100%;
}