@use '~@angular/material' as mat;
@import "~@angular/material/theming";
@import './rina-colors.scss';

$dark-theme-primary: mat.define-palette($rina-grayweb, 50, 100, 500, 900); // most widely used across all screen and components
$dark-theme-accent: mat.define-palette($rina-blue, 50, 100, 500, 900); // floating action color and interactive element
$dark-theme-foreground: mat.define-palette($rina-lightblue, 50, 100, 500, 900); // for text and icons
$dark-theme-warn: mat.define-palette($rina-warn, 50, 100, 500, 900);
$dark-theme: mat.define-dark-theme($dark-theme-primary, $dark-theme-accent, $dark-theme-foreground);

@function color-get($map, $key, $opacity) { // input palette, color number, opacity  --> output rgba
  $value: map-get($map, $key);
  @return rgba($value, $opacity);
}

.light-theme {
  .drawer {
    background-image: linear-gradient(color-get($dark-theme-accent, 500, 1), color-get($dark-theme-foreground, 500, 1));
  }
  .sidenav {
    background-image: linear-gradient(color-get($dark-theme-accent, 500, 1), color-get($dark-theme-foreground, 500, 1));
  }

  %page-toolbar {
    height: 48px;
    color: white;
    background: mat.get-color-from-palette($rina-lightblue, 500);

    mat-nav-list mat-list-item,
    mat-list mat-list-item {
      color: white;
      &.active {
        color: mat.get-color-from-palette($rina-darkblue, 500);
      }
    }
  }

  cube-carbon-credit,
  cube-carbon-credit-validation,
  cube-carbon-credit-details,
  cube-carbon-credit-editor,
  cube-carbon-function,
  cube-carbon-function-editor,
  cube-carbon-function-validation,
  cube-carbon-function-validation-details,
  cube-carbon-production-management,
  cube-carbon-production-editor {
    .mat-toolbar.page-toolbar {
      @extend %page-toolbar;
    }
  }
  %rina-text {
    color: mat.get-color-from-palette($rina-darkblue, 500, 0.7);

    &:hover {
      color: mat.get-color-from-palette($rina-darkblue, 500, 1);
    }
  }

  .mat-tooltip {
    background: mat.get-color-from-palette($rina-gray, 500, 0.9);
  }

  .mat-drawer-container {
    color: mat.get-color-from-palette($rina-darkblue, 500, 0.7);
    background-color: mat.get-color-from-palette($rina-grayweb, 500);
  }

  cube-sidemenu {
    $dark-theme-primary: mat.define-palette($rina-lightblue, 500, 100, 800);
    $dark-theme-accent: mat.define-palette($rina-darkblue, 500, 100, 800);
    $dark-theme-warn: mat.define-palette($rina-warn);
    $dark-theme: mat.define-dark-theme($dark-theme-primary, $dark-theme-accent, $dark-theme-warn);
    @include mat.all-component-themes($dark-theme);
    @include mat.list-theme($dark-theme);
  }

  cube-toolbar {
    .mat-toolbar {
      background: mat.get-color-from-palette($rina-grayweb, 500);
      color: mat.get-color-from-palette($rina-darkblue, 500);
    }
  }

  mat-form-field {
    &.mat-form-field-appearance-outline {
      .mat-form-field-outline {
        // color: mat-color($rina-blue, 500);
      }

      .mat-form-field-outline-thick {
        color: mat.get-color-from-palette($rina-lightblue, 500);
      }
    }
  }

  .toolbar-user-container {
    .dropdown {
      background: color-get($dark-theme-primary, 900, 1) !important;
    }
  }
  .ag-theme-alpine-dark .ag-header-cell, .ag-theme-alpine-dark .ag-header-group-cell{
    font-weight: 600;
    color: color-get($dark-theme-foreground, 500, 1);
    font-size: 15px;
  }
  .ag-theme-alpine-dark .ag-row .ag-cell {
    display: flex;
    align-items: center;
  }
  .ag-theme-alpine-dark .ag-cell {
    border-bottom: 1px solid mat.get-color-from-palette($rina-darkblue, 500, 0.1);
  }
  .ag-theme-alpine-dark .ag-header-row {
    border-bottom: 1px solid color-get($dark-theme-foreground, 500, 0.8);
  }

  .ag-theme-alpine-dark .ag-row-hover{
    background-color: mat.get-color-from-palette($rina-darkblue, 500, 0.1);
  }

  ag-grid-angular {
    .ag-root ::-webkit-scrollbar-thumb {
      background: #b3aeae;
      border-radius: 6px;
    }
    .ag-root ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      background: #eeeeee;
    }
  }
}
