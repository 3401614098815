@use '~@angular/material' as mat;
@import "~@angular/material/theming";
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import "./rina-colors.scss";
@include mat.core();
@import "typography.scss";
@import "../app/cube/cube.theme.scss";
@import "../app/core/toolbar/toolbar.theme.scss";
@import "../app/core/sidemenu/sidemenu.theme.scss";
@import "../app/core/sidemenu-item/sidemenu-item.theme.scss";
@import "../app/pages/error/unauthorized/unauthorized.theme.scss";
@import "~@ag-grid-community/all-modules/dist/styles/ag-grid.css";
@import "~@ag-grid-community/all-modules/dist/styles/ag-theme-material.css";
@mixin mat-components-theme($theme) {
  .mat-list-base {
    .mat-list-item {
      font-size: 14px;
    }
    .mat-list-option {
      font-size: 14px;
    }
  }
}

@mixin custom-components-theme($theme) {
  @include cube-theme($theme);
  @include cube-card-theme($theme);
  @include cube-toolbar-theme($theme);
  @include cube-unauthorized-theme($theme);

  cube-sidemenu-item {
    @include sidemenu-item-theme($theme, 20px);
  }
}

// Default colors
$light-theme-primary: mat.define-palette($rina-blue, 500, 100, 800);
$light-theme-accent: mat.define-palette($rina-lightblue, 500, 100, 800);
$light-theme-warn: mat.define-palette($rina-warn);
$light-theme: mat.define-light-theme($light-theme-primary, $light-theme-accent, $light-theme-warn);

@include mat.all-component-themes($light-theme);
@include custom-components-theme($light-theme);
@include mat-components-theme($light-theme);

.light-theme {
  @include mat.all-component-themes($light-theme);
  @include mat-components-theme($light-theme);
  @include custom-components-theme($light-theme);
}

@import "light-theme.scss";

$dark-theme-primary: mat.define-palette($rina-lightblue, 500, 100, 800);
$dark-theme-accent: mat.define-palette($rina-darkblue, 500, 100, 800);
$dark-theme-warn: mat.define-palette($rina-warn);
$dark-theme: mat.define-dark-theme($dark-theme-primary, $dark-theme-accent, $dark-theme-warn);

.dark-theme {
  @include mat.all-component-themes($dark-theme);
  @include mat-components-theme($dark-theme);
  @include custom-components-theme($light-theme);
}

@import "dark-theme.scss";

html {
  height: 100%;
}

body {
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;
  font: 400 14px/24px Roboto, "Helvetica Neue", sans-serif;
}

mat-toolbar.bg-logo,
mat-toolbar.bg-logo.bg12294b {
  position: relative;
  background: #12294b !important;

  h1.logo {
    font-size: 20px;
    color: #ffffff;
    margin-left: 15px;
  }

  a.home {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

mat-toolbar.bg-logo.bg231f20 {

  h1.logo {
    color: #474747 !important;
    }
}

mat-toolbar.bg-logo.bg00598a {
  background: #00598a;

  h1.logo {
    color: white !important;
    font-style: oblique;
  }
}

.components-container-gt-xs {
  padding: 20px;
}

.components-container-xs {
  padding: 5px !important;
}

.component-preview {
  padding: 20px;
}

.ps-content {
  height: 100%;
}

.sidenav-handler-button-spacer {
  width: 24px;
  transition: width 0.2s;
}

.sidenav-handler-button-spacer-opened {
  width: 344px;
}

.sidenav-handler-button {
  opacity: 0.65;
  position: absolute;
  top: 0;
  left: 0;
  height: 48px;
  width: 24px;
  color: rgba(255, 255, 255, 0.87);

  background: transparent;
  cursor: pointer;
  transition: opacity 0.2s ease 0.5s;
  z-index: 9999;
}

.sidenav-handler-button:hover {
  width: 32px;
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  transition: 0.5s;
}

.sidenav-handler-button-opened {
  opacity: 0.65;
  left: 340px;
  transition: 0.5s;
}

.target-dataset-dialog-backdrop {
  background-color: rgba(0, 0, 0, 0.7);
}

.loader-container {
  background-color: white;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
  position: absolute;
  -moz-animation: cssAnimation 1s ease-in 3s forwards;
  /* Firefox */
  animation: cssAnimation 1s ease-in 3s forwards;
  /* Safari and Chrome */
  -o-animation: cssAnimation 1s ease-in 3s forwards;
  /* Opera */
  animation-fill-mode: forwards;
}

.mat-drawer-container {
  background-color: #f3f3f3;
}

mat-toolbar {
  .spacer {
    flex: 1 1 auto;
  }
}

mat-dialog-container {
  padding: 0 !important;
}

@keyframes cssAnimation {
  to {
    opacity: 0;
    display: none;
    visibility: hidden;
  }
}

@keyframes cssAnimation {
  to {
    visibility: hidden;
    opacity: 0;
    display: none;
  }
}

.spinner {
  margin: 50vh auto 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce3 {
  background-color: #13294b;
}

.spinner .bounce1 {
  background-color: #3eb1c8;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  background-color: #0076a5;
  animation-delay: -0.16s;
}


.cube-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: #eee;
}

.cube-scrollbar::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 6px;
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

ag-grid-angular {
  .ag-header-cell-label {
    justify-content: center;
  }
  .ag-row .ag-cell {
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center;
    //border-left: 1px solid rgba(19, 41, 75, 0.1);
  }
}
