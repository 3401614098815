/*
 * Material Design Icons
 */
/*
@font-face {
  font-family: 'MaterialIcons';
  font-style: normal;
  font-weight: 400;
  src: local('MaterialIcons'),
  local('MaterialIcons-Regular'),
  url('/assets/fonts/material-design-icons/MaterialIcons-Regular.woff2'),
  url('/assets/fonts/material-design-icons/MaterialIcons-Regular.woff'),
  url('/assets/fonts/material-design-icons/MaterialIcons-Regular.ttf');
}

.material-icons {
  font-family: 'MaterialIcons', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  // Preferred icon size
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}
*/
