// Dark theme
@use '~@angular/material' as mat;
@import "~@angular/material/theming";
@import './rina-colors.scss';


$dark-theme-primary: mat.define-palette($rina-darkgray, 50, 100, 500, 900); // most widely used across all screen and components
$dark-theme-accent: mat.define-palette($rina-blue, 50, 100, 500, 900); // floating action color and interactive element
$dark-theme-foreground: mat.define-palette($rina-lightblue, 50, 100, 500, 900); // for text and icons
$dark-theme-warn: mat.define-palette($rina-warn, 50, 100, 500, 900);
$dark-theme: mat.define-dark-theme($dark-theme-primary, $dark-theme-accent, $dark-theme-foreground);

@function color-get($map, $key, $opacity) { // input palette, color number, opacity  --> output rgba
  $value: map-get($map, $key);
  @return rgba($value, $opacity);
}

.dark-theme {

  .drawer {
    background-image: linear-gradient(rgba(35, 31, 32, 1), rgb(66, 66, 66));
  }

  .sidenav {
    background-image: linear-gradient(rgba(35, 31, 32, 1), rgb(66, 66, 66));
  }


  %page-toolbar {
    background: color-get($dark-theme-primary, 900, 1);
    color: white;
    font-weight: 400;
    height: 48px;
    position: sticky;
    top: 0;
    z-index: 3;


    mat-nav-list mat-list-item,
    mat-list mat-list-item {
      color: white;

      &.active {
        color: color-get($dark-theme-accent, 500, 1);
      }
    }
  }

  cube-carbon-credit,
  cube-carbon-credit-validation,
  cube-carbon-credit-details,
  cube-carbon-credit-editor,
  cube-carbon-function,
  cube-carbon-function-editor {
    .mat-toolbar.page-toolbar {
      @extend %page-toolbar;
    }
  }


  %rina-text {
    color: mat.get-color-from-palette($rina-darkblue, 500, 0.7);

    &:hover {
      color: mat.get-color-from-palette($rina-darkblue, 500, 1);
    }
  }

  .mat-drawer-container {
    color: mat.get-color-from-palette($dark-theme-primary, 50, 0.7);
    background-color: color-get($dark-theme-primary, 800, 1);
  }

  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: white;
  }

  .mat-body-1 {
    color: white;
  }

  .mat-body-2 {
    color: white;
  }

  .mat-raised-button.mat-primary,
  .mat-fab.mat-primary,
  .mat-mini-fab.mat-primary {
    color: white;
  }

  .mat-flat-button.mat-primary {
    color: white;
  }

  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
    border-color: color-get($dark-theme-foreground, 500, 1);
    color: white;
  }

  .mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element,
  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: color-get($dark-theme-foreground, 500, 1);
  }

  cube-sidemenu {
    $dark-theme-primary: mat.define-palette($rina-lightblue, 500, 100, 800);
    $dark-theme-accent: mat.define-palette($rina-darkblue, 500, 100, 800);
    $dark-theme-warn: mat.define-palette($rina-warn);
    $dark-theme: mat.define-dark-theme($dark-theme-primary, $dark-theme-accent, $dark-theme-warn);
    @include mat.all-component-themes($dark-theme);
    @include mat.list-theme($dark-theme);
  }


  .mat-toolbar.page-toolbar {
    @extend %page-toolbar;

    .mat-drawer {
      background-color: color-get($dark-theme-primary, 800, 1);
    }
  }

  .toolbar-user-container,
  .toolbar-notification-container {
    .dropdown {
      background: color-get($dark-theme-primary, 900, 1) !important;
    }
    .header {
      background: color-get($dark-theme-primary, 900, 1) !important;
      color: color-get($dark-theme-primary, 50, 1) !important;
      border-bottom: 1px solid color-get($dark-theme-primary, 500, 1) !important;
    }
    .footer {
      border-top: 1px solid color-get($dark-theme-primary, 500, 1) !important;
    }

  }


  .ag-theme-alpine-dark .ag-header-cell, .ag-theme-alpine-dark .ag-header-group-cell{
    font-weight: 600;
    color: color-get($dark-theme-foreground, 500, 1);
    font-size: 15px;
  }
  .ag-theme-alpine-dark .ag-row .ag-cell {
    display: flex;
    align-items: center;
  }
  .ag-theme-alpine-dark .ag-cell {
    border-bottom: 1px solid #454747;
  }
  .ag-theme-alpine-dark .ag-header-row {
    border-bottom: 1px solid color-get($dark-theme-foreground, 500, 0.8);
  }

  .ag-theme-alpine-dark .ag-row-hover{
    background-color: color-get($dark-theme-primary, 900, 0.8);
  }

  .ag-theme-alpine-dark .no-border.ag-cell:focus{
    outline: none;
  }

  ag-grid-angular {
    .ag-root ::-webkit-scrollbar-thumb {
      background: #656464;
      border-radius: 6px;
    }
    .ag-root ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      background: #b3aeae;
    }
  }


}
