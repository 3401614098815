@use '~@angular/material' as mat;
@import "~@angular/material/theming";

@mixin cube-unauthorized-theme($theme) {
    $primary: map-get($theme, accent);
    $warn: map-get($theme, warn);

    cube-unauthorized {
        color: mat.get-color-from-palette($warn);
        background-color: #e0e0e1;
    }
}
